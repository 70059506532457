import androidIcon from "../assets/img/android_svg.svg";
import iosIcon from "..//assets/img/apple_svg.svg";
import startIcon from "..//assets/img/star.png";

function HorizontalCardView({ game, onItemClick }) {
  console.log(game.title);

  return (
    <div class="inline-block px-3" onClick={() => onItemClick(game)}>
      <div class="w-52 h-38 max-w-xs overflow-hidden flex flex-col">
        <div class="w-52 h-28 bg-red-400 rounded-lg relative shadow-sm">
          <img
            alt="main_image"
            class="w-52 h-28 bg-red-400 rounded-lg "
            src={game.imagePath}
          ></img>

          <div class="w-10 h-4 absolute top-0 right-0 mr-2 mt-2 flex flex-row items-center justify-evenly bg-white rounded-md">
            <img
              alt="android"
              style={{ display: game.isForAndroid ? "block" : "none" }}
              class="w-4 h-3 rounded-lg"
              src={androidIcon}
            ></img>

            <img
              alt="ios"
              style={{ display: game.isForIos ? "block" : "none" }}
              class="w-3 h-3 rounded-lg"
              src={iosIcon}
            ></img>
          </div>
        </div>
        <div class="flex flex-row mt-4">
          <img
            alt="icon_image"
            class="w-12 h-12 bg-blue-200 rounded-lg"
            src={game.iconPath}
          ></img>
          <div class="flex flex-col ml-4">
            <div class="text-sm font-cario font-bold">{game.title}</div>
            <div class="text-xs  font-cario">{game.description}</div>
            <div class="flex flex-row items-center mt-1 ">
              <div class=" text-xs font-cario font-bold text-gray-600">
                {game.rating}
              </div>
              <img alt="star" class="w-3 h-3 ml-0.5" src={startIcon}></img>
              <div class=" ml-2 text-xs font-cario font-bold text-gray-600">
                {game.size}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HorizontalCardView;
