import poppyIcon from "../assets/img/poppy_icon.png";
import poppyMain from "../assets/img/poppy_main.JPG";
import mw2Icon from "../assets/img/mw2_logo.jpg";
import mw2Main from "../assets/img/mw2_main.jpg";
import disneyIcon from "../assets/img/disney_icon.jpg";
import disneyMain from "../assets/img/disney_main.jpg";
import bendyIcon from "../assets/img/bendy_icon.jpg";
import bendyMain from "../assets/img/bendy_main.png";
import collegeBrawlMain from "../assets/img/college_brawl_main.jpg";
import collegeBrawlIcon from "../assets/img/college_brawl_icon.jpg";
import pokemonsvMain from "../assets/img/pokemon_s_v_main.jpg";
import pokemonsvIcon from "../assets/img/pokwmon_s_v_icon.jpg";
import beamIcon from "../assets/img/beam_icon.jpg";
import beamMain from "../assets/img/beam_main.jpg";
import haileyIcon from "../assets/img/hailey_icon.jpg";
import haileyMain from "../assets/img/hailey_main.jpg";
import fapMain from "../assets/img/fap_main.jpg";
import fapIcon from "../assets/img/fap_icon.jpg";
import rumbleMain from "../assets/img/rumble_main.jpg";
import rumbleIcon from "../assets/img/rumble_icon.jpg";
import chooMain from "../assets/img/choo_main.jpg";
import chooIcon from "../assets/img/choo_icon.jpg";
import tlsMain from "../assets/img/tls_main.jpg";
import tlsIcon from "../assets/img/tls_icon.jpg";
import ppMain from "../assets/img/ppMain.jpg";
import ppIcon from "../assets/img/ppIcon.jpg";
import ddmMain from "../assets/img/ddmMain.jpg";
import ddmIcon from "../assets/img/ddmIcon.jpg";
import tagAfterMain from "../assets/img/tagAfterMain.jpg";
import tagAfterIcon from "../assets/img/tagAfterIcon.jpg";
import hustleMain from "../assets/img/hustle_main.jpg";
import hustleIcon from "../assets/img/hustle_icon.jpg";
import anotherGirlIcon from "../assets/img/another_girl_icon.png";
import anotherGirlMain from "../assets/img/another_girl_main.jpg";
import spokyIcon from "../assets/img/spoky_icon.jpg";
import spokyMain from "../assets/img/spoky_main.jpg";
import castelIcon from "../assets/img/castle_icon.jpg";
import castelMain from "../assets/img/castle_main.jpg";
import dwarfIcon from "../assets/img/dwarf_icon.png";
import dwarfMain from "../assets/img/dwarf_main.jpg";
import squidIcon from "../assets/img/64-2.jpg";
import squidMain from "../assets/img/64-2.jpg";
import cocoMain from "../assets/img/coco_main.jpg";
import cocoIcon from "../assets/img/coco_icon.png";
import schoolMain from "../assets/img/scholl_main.jpg";
import schoolIcon from "../assets/img/school_icon.jpg";
import sonolusMain from "../assets/img/sonolus_main.jpg";
import sonolusIcon from "../assets/img/sonolus_icon.png";
import pokeAbbyMain from "../assets/img/poke_abby_main.jpg";
import pokeAbbyIcon from "../assets/img/poke_abby_icon.png";
import gachaNoxMain from "../assets/img/gacha_nox_main.jpg";
import gachaNoxIcon from "../assets/img/gacha_nox_icon.jpg";
import sisterFightMain from "../assets/img/sister_fight_main.jpg";
import sisterFightIcon from "../assets/img/sister_fight_icon.jpg";
import animeImg from "../assets/img/anime_icon.png";
import fnfIcon from "../assets/img/fnf_icon.png";
import fnfMain from "../assets/img/fnf_main.jpg";
import jadeIcon from "../assets/img/jade_icon.png";
import jadeMain from "../assets/img/jade_main.jpg";
import muffIcon from "../assets/img/muff_icon.png";
import muffMain from "../assets/img/muff_main.jpg";
import nightShiftIcon from "../assets/img/night_shift_icon.png";
import nightShiftMain from "../assets/img/night_shift_main.jpg";
import ohioIcon from "../assets/img/ohio_icon.png";
import ohioMain from "../assets/img/ohio_main.jpg";
import slovenIcon from "../assets/img/sloven_icon.jpg";
import slovenMain from "../assets/img/sloven_main.png";
import kameIcon from "../assets/img/kame_icon.png";
import kameMain from "../assets/img/kame_main.jpg";
import meta5Img from "../assets/img/meta_5_icon.png";
import parsecIcon from "../assets/img/parsec_icon.png";
import parsecMain from "../assets/img/parsec_main.jpg";
import erosIcon from "..//assets/img/eros_icon.png";
import erosMain from "../assets/img/eros_main.jpg";
import melatoninIcon from "../assets/img/melatonin_icon.jpg";
import melatoninMain from "../assets/img/melatonin_main.jpg";
import backMain from "../assets/img/back_main.jpg";
import backIcon from "../assets/img/back_icon.png";
import nodoriosIcon from "../assets/img/nodorios_icon.png";
import nodoriosMain from "../assets/img/nodorios_main.jpg";
import learnMain from "../assets/img/learn_main.jpg";
import learnIcon from "../assets/img/learn_icon.jpg";
import narutoMain from "../assets/img/naruto_main.jpg";
import narutoIcon from "../assets/img/naruto_icon.jpg";

import powerIcon from "../assets/img/power_icon.jpg";
import powerMain from "../assets/img/power_main.jpg";

import codenameIcon from "../assets/img/codename_icon.jpg";
import codenameMain from "../assets/img/codename_main.jpg";

import hitoriIcon from "../assets/img/hitori_icon.png";
import hitoriMain from "../assets/img/hitori_main.jpg";

import gartenIcon from "../assets/img/garten_icon.png";
import gartenMain from "../assets/img/garten_main.jpg";

import cherryIcon from "../assets/img/cherry_icon.png";
import cherryMain from "../assets/img/cherry_main.jpg";

import zomboidIcon from "../assets/img/zomboid_icon.png";
import zomboidMain from "../assets/img/zomboid_main.jpg";

import kpm2Img from "../assets/img/kpm2_icon.png";
import gachaPopIcon from "../assets/img/gacha_pop_icon.png";
import gachaPopMain from "../assets/img/gacha_pop_main.jpg";

import villageImg from "../assets/img/village_img.jpg";
import pokemonQueImg from "../assets/img/pokemon_que_img.jpg";
import touchMain from "../assets/img/touch_main.jpg";
import touchIcon from "../assets/img/touch_icon.jpg";

import ouroImg from "../assets/img/ouro_img.png";

import forbiddenIcon from "../assets/img/forbidden_icon.jpg";
import faithIcon from "../assets/img/faith_icon.png";
import pizzaIcon from "../assets/img/pizza_icon.png";
import holocureIcon from "../assets/img/holocure_icon.jpg";

import holeIcon from "../assets/img/hole_icon.jpg";
import holeMain from "../assets/img/hole_main.jpg";
import omoriIcon from "../assets/img/omori_icon.png";
import poja from "../assets/img/poja_icon.png";
import cineIcon from "../assets/img/cine_icon.png";
import syahataIcon from "../assets/img/syahata_icon.jpg";

import paioImg from "../assets/img/paio_img.png";
import forestImg from "../assets/img/forest_img.png";
import lustImg from "../assets/img/lust_img.png";
import melanImg from "../assets/img/melan_img.png";

import kisakiIcon from "../assets/img/kisaki_icon.png";
import kisakiMain from "../assets/img/kisaki_main.jpg";

export const gameData = [
  {
    uniqueId: 45,
    title: "Poppy Playtime - Chapter 2",
    description:
      "Poppy Playtime Chapter 2 is an adventure game. The game includes a number of useful features that will help you uncover shocking truths.",
    imagePath: poppyMain,
    iconPath: poppyIcon,
    rating: 4.9,
    totalReviews: "10k reviews",
    download: "18k+",
    author: "poppy++",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "66MB",
    versionName: "poppy_c_2.1.1.6.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://downloadlocked.com/cl/i/o69wxd",
  },
  {
    uniqueId: 46,
    title: "Call of Duty MW2 Mobile",
    description:
      "Call of Duty: Modern Warfare II is a 2022 first-person shooter game developed by Infinity Ward and published by Activision.",
    imagePath: mw2Main,
    iconPath: mw2Icon,
    rating: 4.7,
    totalReviews: "12k reviews",
    download: "18k+",
    author: "Activizion++",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "1.2Gb",
    versionName: "cod_mw2.1.6.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://installchecker.com/cl/i/1opvep",
  },
  {
    uniqueId: 47,
    title: "Disney Dreamlight Valley",
    description:
      "Disney Dreamlight Valley is a life simulation-adventure video game developed and published by Gameloft based on and featuring various characters owned by The Walt Disney Company",
    imagePath: disneyMain,
    iconPath: disneyIcon,
    rating: 4.6,
    totalReviews: "8k reviews",
    download: "10k+",
    author: "Gameloft",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "700Mb",
    versionName: "dreamlight1.1.2.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://appverification.net/cl/i/ndv8o6",
  },
  {
    uniqueId: 48,
    title: "Bendy and the Dark Revival",
    description:
      "Bendy and the Dark Revival™ is a first-person survival horror game set in the creepiest cartoon studio to ever exist.",
    imagePath: bendyMain,
    iconPath: bendyIcon,
    rating: 4.6,
    totalReviews: "3k reviews",
    download: "7k+",
    author: "Joey Drew Studios",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "210Mb",
    versionName: "bendy2.1.3.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://appinstallcheck.com/cl/i/42p3l1",
  },
  {
    uniqueId: 49,
    title: "College Brawl",
    description:
      "College Brawl 2-dimensional game where you act as a mischievous boy who bullies everybody.",
    imagePath: collegeBrawlMain,
    iconPath: collegeBrawlIcon,
    rating: 4.3,
    totalReviews: "1k reviews",
    download: "1.5k+",
    author: "Cb Studios",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "100Mb",
    versionName: "college1.1.1.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://verifysuper.com/cl/i/4212vx",
  },
  {
    uniqueId: 50,
    title: "Pokemon Scarlet and Violet",
    description:
      "Pokémon Scarlet and Pokémon Violet are the first open-world RPGs in the Pokémon series.",
    imagePath: pokemonsvMain,
    iconPath: pokemonsvIcon,
    rating: 4.9,
    totalReviews: "6k reviews",
    download: "10k+",
    author: "Game Freak",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "230Mb",
    versionName: "p_s_v1.2.1.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://locked4.com/cl/i/r7r7pd",
  },
  {
    uniqueId: 51,
    title: "BeamNG Drive",
    description:
      "Vehicles. BeamNG.drive offers dozens of refined, completely customizable vehicles to experiment with.",
    imagePath: beamMain,
    iconPath: beamIcon,
    rating: 4.2,
    totalReviews: "2k reviews",
    download: "16k+",
    author: "BeamNG",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "570Mb",
    versionName: "beamNG2.4.1.ipa",
    ridirectUrl: "https://appverification.net/cl/i/klxlpl",
  },
  {
    uniqueId: 52,
    title: "Hailey's Treasure Adventure",
    description:
      "Hailey's Treasure Adventure is a simple adventure game with many puzzles. The game has an exciting story, making it immersive for players.",
    imagePath: haileyMain,
    iconPath: haileyIcon,
    rating: 4.0,
    totalReviews: "1k reviews",
    download: "2k+",
    author: "LAGS",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "170Mb",
    versionName: "h_t_a.4.1.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://verifysuper.com/cl/i/ndmdp6",
  },
  {
    uniqueId: 53,
    title: "Fap Nights at Frenni’s Night Club",
    description:
      "Now, as a night guard of the place you always wanted to access as a child, you will be able to meet Frenni Fazclaire the bear and her ...",
    imagePath: fapMain,
    iconPath: fapIcon,
    rating: 4.8,
    totalReviews: "10k reviews",
    download: "12k+",
    author: "FATAL FIRE",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "210Mb",
    versionName: "frenni1.2.0.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://verifyuser.org/cl/i/dvkvwo",
  },
  {
    uniqueId: 54,
    title: "Rumbleverse",
    description:
      "This city was built to celebrate the glorious traditions of melee combat",
    imagePath: rumbleMain,
    iconPath: rumbleIcon,
    rating: 4.4,
    totalReviews: "2k reviews",
    download: "8k+",
    author: "Iron Galaxy",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "419Mb",
    versionName: "rumble1.1.7.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://locked4.com/cl/i/vovo7d",
  },
  {
    uniqueId: 55,
    title: "Choo-Choo Charles Mobile",
    description:
      "Choo-Choo Charles mobile version for iOS and Android. Gameplay consists of travelling across an open world map in a train, performing tasks for non-player characters, collecting loot.....",
    imagePath: chooMain,
    iconPath: chooIcon,
    rating: 4.5,
    totalReviews: "10k reviews",
    download: "52k+",
    author: "Two Star Games",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "232Mb",
    versionName: "choo.1.0.1.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://appinstallcheck.com/cl/i/8dnvoj",
  },
  {
    uniqueId: 56,
    title: "Trader Life Simulator 2",
    description:
      "Trader Life Simulator 2 for iOS and Android. Trader Life Simulator 2 is a game where you can open your own Supermarket.....",
    imagePath: tlsMain,
    iconPath: tlsIcon,
    rating: 4.2,
    totalReviews: "2k reviews",
    download: "32k+",
    author: "DNA ARMY GAMING",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "344Mb",
    versionName: "tls.2.0.1.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://locked4.com/cl/i/g6vj5d",
  },
  {
    uniqueId: 57,
    title: "Project Playtime",
    description:
      "Project Playtime for iOS and Android. Project Playtime : play co-op horror experience set in the Playtime toy factory!",
    imagePath: ppMain,
    iconPath: ppIcon,
    rating: 4.5,
    totalReviews: "4k reviews",
    download: "12k+",
    author: "Visible Actions",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "224Mb",
    versionName: "Latest",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://installchecker.com/cl/i/7dkv89",
  },
  {
    uniqueId: 58,
    title: "Different Dimension Me",
    description:
      "Different Dimension Me for iOS and Android. Different Dimension Me is an AI website that turns an image into anime.",
    imagePath: ddmMain,
    iconPath: ddmIcon,
    rating: 4.9,
    totalReviews: "4k reviews",
    download: "12k+",
    author: "QQ Actions",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "84Mb",
    versionName: "Latest",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://locked2.com/cl/i/po1r4n",
  },
  {
    uniqueId: 59,
    title: "Tag After School P2",
    description:
      " Tag After School Mobile is an immersive game that puts you in charge of Shota-Kun.",
    imagePath: tagAfterMain,
    iconPath: tagAfterIcon,
    rating: 4.8,
    totalReviews: "5k reviews",
    download: "43k+",
    author: "DeveloperGenius Inc",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "300Mb",
    versionName: "Latest",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://mobileverify.net/cl/i/5k4l81",
  },
  {
    uniqueId: 60,
    title: "Another girl in the wall",
    description:
      "A fun interactive game that you can play on your Android device or Windows , iOS where you will control a girl who is stuck in the wall, you will have many different options to choose",
    imagePath: anotherGirlMain,
    iconPath: anotherGirlIcon,
    rating: 4.4,
    totalReviews: "5k reviews",
    download: "33k+",
    author: "Itch.io",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "189MB",
    versionName: "2.1.2.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://installchecker.com/cl/i/4217n4",
  },
  {
    uniqueId: 61,
    title: "Spooky Milk Life",
    description:
      "Spooky Milk Life is a high-quality r-18 adult-only adventure game with a full-featured simulation date!",
    imagePath: spokyMain,
    iconPath: spokyIcon,
    rating: 4.2,
    totalReviews: "7k reviews",
    download: "21k+",
    author: "Dash",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "190MB",
    versionName: "1.4.2.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://appinstallcheck.com/cl/i/r7r4vl",
  },
  {
    uniqueId: 62,
    title: "Castle Of Temptation",
    description:
      "The Castle of Temptation takes place in a fantastical world full of magical creatures and mysterious",
    imagePath: castelMain,
    iconPath: castelIcon,
    rating: 4.5,
    totalReviews: "8k reviews",
    download: "41k+",
    author: "Sonos",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "200MB",
    versionName: "2.4.1.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //"https://verifyuser.org/cl/i/klxn38",
  },
  {
    uniqueId: 63,
    title: "Dwarf Fortress",
    description:
      "Dwarf Fortress is a construction and management simulation and roguelike indie video game",
    imagePath: dwarfMain,
    iconPath: dwarfIcon,
    rating: 4.1,
    totalReviews: "2k reviews",
    download: "14k+",
    author: "Bay 12",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "160MB",
    versionName: "3.4.3.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    // "https://verifyuser.org/cl/i/ndmern",
  },
  {
    uniqueId: 64,
    title: "Your Only Move Is HUSTLE",
    description:
      "Slow down the clock to fine-tune your fighting style in this innovative,",
    imagePath: hustleMain,
    iconPath: hustleIcon,
    rating: 3.9,
    totalReviews: "1k reviews",
    download: "10k+",
    author: "Ivy Sly",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "180MB",
    versionName: "2.2.2.ipa",
    ridirectUrl: "https://nolocker.com/",
    //ridirectUrl:
    //  "https://mobileverify.net/cl/i/dvk39w",
  },
  {
    uniqueId: 65,
    title: "Squid Honey",
    description:
      "A really enjoyable game that takes its cues from the well-known Squid game..",
    imagePath: squidMain,
    iconPath: squidIcon,
    rating: 3.9,
    totalReviews: "2k reviews",
    download: "15k+",
    author: "Wayne",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "154MB",
    versionName: "1.6.2.ipa",
    ridirectUrl: "https://nolocker.com/",
    //"https://appverification.net/cl/i/vovdxg",
  },
  {
    uniqueId: 66,
    title: "Sister Fight",
    description:
      "Sister Fight for iOS and Android (Latest Version). Download Sister Fight 2D fighting game that allows you to control Orcs and fight the sister of faith.",
    imagePath: sisterFightMain,
    iconPath: sisterFightIcon,
    rating: 4.3,
    totalReviews: "1k reviews",
    download: "10k+",
    author: "KooooN Soft",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "124MB",
    versionName: "1.2.2.ipa",
    ridirectUrl: "https://nolocker.com/",
    //"https://downloadlocked.com/cl/i/5k4mw3",
  },
  {
    uniqueId: 67,
    title: "Poke Abby",
    description:
      "Poke Abby mobile game for iOS and Android. Poke Abby is a 3D interactive sex game taking place at the Autumnhearth College",
    imagePath: pokeAbbyMain,
    iconPath: pokeAbbyIcon,
    rating: 4.8,
    totalReviews: "14k reviews",
    download: "24k+",
    author: "SD",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "112MB",
    versionName: "1.2.2.ipa",
    ridirectUrl: "https://nolocker.com/",
    //"https://installchecker.com/cl/i/me91vw",
  },
  {
    uniqueId: 68,
    title: "Coco Nutshake",
    description:
      "Coco Nutshake game for iOS and Android. Play for score in a 3-stage arcade fashion, getting a coconut milkshake from our girl .....",
    imagePath: cocoMain,
    iconPath: cocoIcon,
    rating: 4.6,
    totalReviews: "4k reviews",
    download: "10k+",
    author: "ahegames",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "114MB",
    versionName: "1.2.5.ipa",
    ridirectUrl: "https://nolocker.com/",
    //"https://locked3.com/cl/i/1o91lp",
  },
  {
    uniqueId: 69,
    title: "School Dot Fight",
    description:
      "School Dot Fight mobile game for iOS and Android Latest Version. Download School Dot Fight to experience the thriller of a high school battle. Play .....",
    imagePath: schoolMain,
    iconPath: schoolIcon,
    rating: 4.0,
    totalReviews: "14k reviews",
    download: "26k+",
    author: "ahegames",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "189MB",
    versionName: "2.1.5.ipa",
    ridirectUrl: "https://nolocker.com/",
    //"https://locked2.com/cl/i/r7r8ok",
  },
  {
    uniqueId: 70,
    title: "Gacha Nox",
    description:
      "Gacha Nox is a modified version or MOD of Gacha Club (a popular Japanese role-playing game.....",
    imagePath: gachaNoxMain,
    iconPath: gachaNoxIcon,
    rating: 4.5,
    totalReviews: "32k reviews",
    download: "96k+",
    author: "Noxula",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "189MB",
    versionName: "2.2.2.ipa",
    ridirectUrl: "https://nolocker.com/",
    //"https://locked1.com/cl/i/klxmqg",
  },
  {
    uniqueId: 71,
    title: "Sonolus",
    description:
      "Sonolus for iOS and Android (latest version). Sonolus is a next generation Mobile Rhythm Game.",
    imagePath: sonolusMain,
    iconPath: sonolusIcon,
    rating: 4.4,
    totalReviews: "12k reviews",
    download: "30k+",
    author: "Sonos",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "139MB",
    versionName: "1.2.1.ipa",
    ridirectUrl: "https://nolocker.com/",
    //"https://locked3.com/cl/i/dvkjm1",
  },
  {
    uniqueId: 72,
    title: "Ohio Stars",
    description: "Brawl Stars in Ohio Apk Full Works for iPhone & Android",
    imagePath: ohioMain,
    iconPath: ohioIcon,
    rating: 4.0,
    totalReviews: "9k reviews",
    download: "15k+",
    author: "Braw",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "154MB",
    versionName: "1.2.3.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 73,
    title: "Jade Chamber Sunshine",
    description:
      "Jade Chamber Sunshine for iOS and Android (latest version v.1.0). Versions of well-known Genshin characters may be found in the game...",
    imagePath: jadeMain,
    iconPath: jadeIcon,
    rating: 4.5,
    totalReviews: "9k reviews",
    download: "15k+",
    author: "NorKoala",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "214MB",
    versionName: "2.2.3.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 74,
    title: "FNF vs Impostor v4",
    description:
      "Experience the ultimate showdown in FNF vs Imposter V4, the newest installment of the Friday Night Funkin' total conversion mod.",
    imagePath: fnfMain,
    iconPath: fnfIcon,
    rating: 4.4,
    totalReviews: "9k reviews",
    download: "43k+",
    author: "NV",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "199MB",
    versionName: "1.1.1.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 75,
    title: "Sloven Classmate",
    description:
      "An example of life through pixel art! Have sex in different places to fulfill your lust.",
    imagePath: slovenMain,
    iconPath: slovenIcon,
    rating: 4.6,
    totalReviews: "19k reviews",
    download: "22k+",
    author: "HF",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "219MB",
    versionName: "1.1.8.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 76,
    title: "Night Shift at Fazclaire's Nightclub",
    description:
      "The story follows an average guy who lives in his property alone. He wanted to get a job to earn money because he's poor..",
    imagePath: nightShiftMain,
    iconPath: nightShiftIcon,
    rating: 4.4,
    totalReviews: "19k reviews",
    download: "22k+",
    author: "AF",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "111MB",
    versionName: "1.1.8.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 77,
    title: "Anime Slayer",
    description:
      "Anime Slayer for iOS and Android (latest version). Allows you to keep track of the latest releases.",
    imagePath: animeImg,
    iconPath: animeImg,
    rating: 4.4,
    totalReviews: "19k reviews",
    download: "22k+",
    author: "senseiTEk",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "222MB",
    versionName: "1.5.4.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 78,
    title: "Muff",
    description:
      "My Unusual Feline Friend is a simulation genre game where you will go on a date with a very Kawai girl.",
    imagePath: muffMain,
    iconPath: muffIcon,
    rating: 4.7,
    totalReviews: "12k reviews",
    download: "20k+",
    author: "apkink",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "92MB",
    versionName: "2.5.4.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 79,
    title: "Kame Paradise 3",
    description:
      "Kame Paradise 3 for iOS and Android. An old master has stolen a time machine and is chasing young girls across the multiverse.",
    imagePath: kameMain,
    iconPath: kameIcon,
    rating: 4.9,
    totalReviews: "2k reviews",
    download: "11k+",
    author: "YAMAMOTO",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "102MB",
    versionName: "1.5.4.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 80,
    title: "Parsec",
    description:
      "Parsec for iOS. Parsec is a proprietary desktop capturing application primarily used for playing games through video streaming.",
    imagePath: parsecMain,
    iconPath: parsecIcon,
    rating: 5.0,
    totalReviews: "20k reviews",
    download: "37k+",
    author: "Unity Technologies",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "67MB",
    versionName: "1.23.0.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 81,
    title: "MetaTrader 5",
    description:
      "MetaTrader 5 for iOS. Forex & Stock brokers offer traders investing in shares and currency trading via MetaTrader 5. ",
    imagePath: meta5Img,
    iconPath: meta5Img,
    rating: 4.5,
    totalReviews: "50k reviews",
    download: "107k+",
    author: "MetaQuotes",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "112MB",
    versionName: "1.9.2.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 82,
    title: "Rise Of Eros",
    description:
      "Rise Of Eros for iOS and Android Latest Version. At the dawn of the world, two originary deities were created from humanity's most primal desire...",
    imagePath: erosMain,
    iconPath: erosIcon,
    rating: 4.8,
    totalReviews: "10k reviews",
    download: "67k+",
    author: "EroLabs",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "142MB",
    versionName: "1.2.2.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 83,
    title: "Nodorios",
    description:
      "Descargar NODORIOS APK, instalar app en Smart TV, Android, PC, TV BOX, Fire Stickpara...",
    imagePath: nodoriosMain,
    iconPath: nodoriosIcon,
    rating: 4.9,
    totalReviews: "8k reviews",
    download: "27k+",
    author: "Futbit",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "49MB",
    versionName: "1.2.10.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 84,
    title: "Back Alley Tales",
    description:
      " The crimes, the help, the terrifying racial things happening in different dark parts of the world is not well known and unveiled in the eye of camera a...",
    imagePath: backMain,
    iconPath: backIcon,
    rating: 4.6,
    totalReviews: "3k reviews",
    download: "22k+",
    author: "URAP",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "119MB",
    versionName: "2.12.10.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 85,
    title: "Melatonin",
    description:
      "Melatonin Game for iOS and Android (Latest Version). Melatonin is a rhythm game about combining dreams and reality. ",
    imagePath: melatoninMain,
    iconPath: melatoninIcon,
    rating: 4.3,
    totalReviews: "13k reviews",
    download: "32k+",
    author: "Half Asleep",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "99MB",
    versionName: "1.9.8.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 86,
    title: "Learn The Heart",
    description:
      "Learn The Heart allows players to explore the world of love and relationships. Apart from various mini-games and activities, you can also give gifts to your partner as part of the game.",
    imagePath: learnMain,
    iconPath: learnIcon,
    rating: 4.5,
    totalReviews: "10k reviews",
    download: "32k+",
    author: "HeartInc",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "120MB",
    versionName: "1.2.10.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 87,
    title: "Naruto Family Vacation",
    description:
      "Naruto Family Vacation for iOS.The game takes place in the Naruto anime universe.",
    imagePath: narutoMain,
    iconPath: narutoIcon,
    rating: 4.5,
    totalReviews: "20k reviews",
    download: "52k+",
    author: "Patreon",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "150MB",
    versionName: "2.2.1.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 88,
    title: "Project Zomboid",
    description:
      "Project Zomboid Survival Challenge Game is here! dead the Zombie.",
    imagePath: zomboidMain,
    iconPath: zomboidIcon,
    rating: 4.0,
    totalReviews: "10k reviews",
    download: "22k+",
    author: "The Indie Stone",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "120MB",
    versionName: "2.2.32.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 89,
    title: "Cherry Tale",
    description:
      "Cherry Tale invites all gamers to love watching beautiful girls with a sweet and equally dramatic story.",
    imagePath: cherryMain,
    iconPath: cherryIcon,
    rating: 5.0,
    totalReviews: "13k reviews",
    download: "20k+",
    author: "Neversoft",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "111MB",
    versionName: "3.1.2.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 90,
    title: "Garten of Banban",
    description:
      "Garten of Banban will definitely make you friends. Be careful not to lose your life and beauty while exploring the mysterious castle.",
    imagePath: gartenMain,
    iconPath: gartenIcon,
    rating: 4.5,
    totalReviews: "13k reviews",
    download: "40k+",
    author: "Euphoric Brothers",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "154MB",
    versionName: "1.4.2.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 91,
    title: "Hitori No Shita: The Outcast",
    description:
      " College student Cho Soran unwittingly discovers his grandfather's secret legacy when warriors from rival clans begin pursuing him for the hidden power he inherited.",
    imagePath: hitoriMain,
    iconPath: hitoriIcon,
    rating: 4.6,
    totalReviews: "9k reviews",
    download: "19k+",
    author: "EB",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "121MB",
    versionName: "1.3.1.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 92,
    title: "Codename Jump",
    description:
      "Download Codename Jump Latest version and enjoy an anime themed MOBA 5v5 multiplayer experience. It has many anime characters & diverse customization options.",
    imagePath: codenameMain,
    iconPath: codenameIcon,
    rating: 4.6,
    totalReviews: "8k reviews",
    download: "10k+",
    author: "Source c++",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "98MB",
    versionName: "0.70.1.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 93,
    title: "Power Warriors",
    description:
      "Power Warriors is a portable 2D fighting game. The set contains: -Battles of up to 3 vs 3 characters -Fun and easy to play -More than 200 characters -24 fight scenarios",
    imagePath: powerMain,
    iconPath: powerIcon,
    rating: 4.9,
    totalReviews: "18k reviews",
    download: "50k+",
    author: "AMAMOTODOUJIN",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "129MB",
    versionName: "1.22.1.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 94,
    title: "Kame Paradise Multiversex 2",
    description:
      "Download and Play Kame Paradise Multiversex 2 Mobile Version on Any iOS and Android Device for Free!",
    imagePath: kpm2Img,
    iconPath: kpm2Img,
    rating: 4.4,
    totalReviews: "8k reviews",
    download: "32k+",
    author: "Arielazo",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "198MB",
    versionName: "2.32.1.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 95,
    title: "Gacha Pop Mod",
    description: "Download Gacha Pop on Any iOS and Android Device.",
    imagePath: gachaPopMain,
    iconPath: gachaPopIcon,
    rating: 4.8,
    totalReviews: "18k reviews",
    download: "23k+",
    author: "Lunime",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "192MB",
    versionName: "1.2.1.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 96,
    title: "Village Rhapsody",
    description:
      "Village Rhapsody perfectly combines the genres of visual novel and farm simulation.",
    imagePath: villageImg,
    iconPath: villageImg,
    rating: 4.3,
    totalReviews: "11k reviews",
    download: "21k+",
    author: "Yoo Games Steam",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "132MB",
    versionName: "1.2.5.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 97,
    title: "Pokemon Quetzal",
    description:
      "Pokemon Quetzal ROM is a GBA Modified ROM, which is the improved edition of Pokémon Emerald.",
    imagePath: pokemonQueImg,
    iconPath: pokemonQueImg,
    rating: 4.5,
    totalReviews: "1k reviews",
    download: "11k+",
    author: "Pokemonlog",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "232MB",
    versionName: "2.2.5.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 98,
    title: "Touch Himawari",
    description:
      "Touch Himawari is a simulation game where you flirt and chat with attractive women. ",
    imagePath: touchMain,
    iconPath: touchIcon,
    rating: 4.8,
    totalReviews: "12k reviews",
    download: "32k+",
    author: "TouchHimawari",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "111MB",
    versionName: "1.5.2.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 99,
    title: "Ouro Player",
    description:
      "A game that not only provide entertainment but also fulfill the desire for having an intimate interaction with someone.",
    imagePath: ouroImg,
    iconPath: ouroImg,
    rating: 4.5,
    totalReviews: "9k reviews",
    download: "22k+",
    author: "NGL FACTORY",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "191MB",
    versionName: "1.5.5.ipa",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 100,
    title: "Pizza Tower",
    description:
      "Pizza Tower is a fast paced 2D platformer inspired by the Wario Land series, with an emphasis on movement, exploration and score attack. ",
    imagePath: pizzaIcon,
    iconPath: pizzaIcon,
    rating: 4.8,
    totalReviews: "9k reviews",
    download: "12k+",
    author: "Tour De Pizza",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "164MB",
    versionName: "v.1.4.1",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 101,
    title: "HoloCure",
    description:
      "Welcome to HOLOCURE! This is a completely free, unofficial fangame featuring the vtuber talents of Hololive.",
    imagePath: holocureIcon,
    iconPath: holocureIcon,
    rating: 4.3,
    totalReviews: "1k reviews",
    download: "5k+",
    author: "Kay Yu",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "132MB",
    versionName: "v.1.4.4",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 102,
    title: "Faith: The Unholy Trinity",
    description:
      " Discover a world of dread, isolation, and mystery as you explore haunted forests, abandoned churches..",
    imagePath: faithIcon,
    iconPath: faithIcon,
    rating: 4.5,
    totalReviews: "4k reviews",
    download: "12k+",
    author: "New Blood Interactive",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "231MB",
    versionName: "v.2.12.1",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 102,
    title: "Forbidden Playground",
    description:
      "Forbidden Playground is a fun-themed interactive game that features gorgeous female characters.",
    imagePath: forbiddenIcon,
    iconPath: forbiddenIcon,
    rating: 4.7,
    totalReviews: "12k reviews",
    download: "32k+",
    author: "Uncanny Salad",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "99MB",
    versionName: "v.1.1.5",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 103,
    title: "Hole House",
    description:
      "Hole House is a game where you come across and old brothel that has just come under new ownership as they are trying to revamp and revitalise the industry.",
    imagePath: holeMain,
    iconPath: holeIcon,
    rating: 3.9,
    totalReviews: "11k reviews",
    download: "22k+",
    author: "DotArtNSFW",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "79MB",
    versionName: "v.1.1.7",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 104,
    title: "Omori",
    description:
      "Omori is a 2020 role-playing video game developed and published by indie studio Omocat. In the game, the player controls a hikikomori teenage.",
    imagePath: omoriIcon,
    iconPath: omoriIcon,
    rating: 4.5,
    totalReviews: "22k reviews",
    download: "49k+",
    author: "OMOCAT, LLC",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "141MB",
    versionName: "v.1.2.1",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 105,
    title: "PojavLauncher",
    description:
      "PojavLauncher for iOS and Android. This application is intended to run one particular LWJGL-based game you all know..",
    imagePath: poja,
    iconPath: poja,
    rating: 3.9,
    totalReviews: "2k reviews",
    download: "10k+",
    author: "artdeell",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "75MB",
    versionName: "v.2.2.3",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 106,
    title: "Cine Mate",
    description:
      " This app is perfect for tracking your movie watching experience. It allows you to quickly search for titles and add them to your watchlist.",
    imagePath: cineIcon,
    iconPath: cineIcon,
    rating: 4.5,
    totalReviews: "22k reviews",
    download: "30k+",
    author: "DangerousPixel",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "125MB",
    versionName: "v.1.2.6",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 107,
    title: "Syahata A Bad Day",
    description:
      "Syahata A Bad Day game gives players new feelings when incarnating into the heroine named Syahata, who saved the city from deadly days by a series of zombies everywhere. ",
    imagePath: syahataIcon,
    iconPath: syahataIcon,
    rating: 4.1,
    totalReviews: "13k reviews",
    download: "18k+",
    author: "Jashin4264",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "113MB",
    versionName: "v.2.2.6",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 108,
    title: "Paio Hazard",
    description:
      "Paio Hazard for iOS and Android. In this game, different beauties are waiting for the player to attack at each level. The story and gameplay of the game are very rich. This is done by the home team. A beautiful mobile game on the theme of love.",
    imagePath: paioImg,
    iconPath: paioImg,
    rating: 4.6,
    totalReviews: "3k reviews",
    download: "18k+",
    author: "digital onahooole",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "103MB",
    versionName: "v.1.12.1",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 109,
    title: "Forest of The Blue Skin",
    description:
      "Forest of The Blue Skin (Fobs) for iOS and Android. Animated characters and well-designed monsters and enemies make the game enjoyable.",
    imagePath: forestImg,
    iconPath: forestImg,
    rating: 4.4,
    totalReviews: "2k reviews",
    download: "8k+",
    author: "zell23",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "99MB",
    versionName: "v.1.15.2",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 110,
    title: "Lustful Spirit Hunt",
    description:
      "On an investigation location, you must attempt to capture a spirit. To find a room that is haunted by ghosts, you must make use of the tools at your disposal (changes every time).",
    imagePath: lustImg,
    iconPath: lustImg,
    rating: 4.5,
    totalReviews: "12k reviews",
    download: "20k+",
    author: "Ingenius Studios",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "129MB",
    versionName: "v.1.4.8",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 111,
    title: "MelanCholianna",
    description:
      "A girl wakes up in a mysterious dungeon and your mission is to help her find her way out of the dungeon full of monsters.",
    imagePath: melanImg,
    iconPath: melanImg,
    rating: 3.9,
    totalReviews: "8k reviews",
    download: "20k+",
    author: "Halfstar",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "131MB",
    versionName: "v.1.0.23",
    ridirectUrl: "https://nolocker.com/",
  },
  {
    uniqueId: 112,
    title: "Kisaki Blue Archive",
    description:
      "Kisaki Blue Archive Gameis an RPG with cute 3D anime girls, the player is a teacher who guides the students to solve different incidents in the city.",
    imagePath: kisakiMain,
    iconPath: kisakiIcon,
    rating: 4.3,
    totalReviews: "18k reviews",
    download: "31k+",
    author: "dong134",
    isForIos: true,
    isForAndroid: true,
    latestGame: true,
    size: "112MB",
    versionName: "v.1.5.2",
    ridirectUrl: "https://nolocker.com/",
  },
];

function getRandomStars() {
  var min = 3.8;
  var max = 5.0;
  return (min + Math.random() * (max - min)).toFixed(1);
}

function getRandomReviews() {
  var min = 5;
  var max = 15;
  return (min + Math.random() * (max - min)).toFixed(0);
}

function getRandomDownload() {
  var min = 15;
  var max = 25;
  return (min + Math.random() * (max - min)).toFixed(0);
}

function getRandomSize() {
  var min = 80;
  var max = 200;
  return (min + Math.random() * (max - min)).toFixed(0);
}

function getRandomVersion() {
  var major = (1 + Math.random() * (2 - 1)).toFixed(0);
  var middle = (0 + Math.random() * (10 - 0)).toFixed(0);
  var minor = (0 + Math.random() * (12 - 0)).toFixed(0);

  return `${major}.${middle}.${minor}`;
}

//https://nolocker.com/
//https://nolocker.com/
