import HorizontalCardView from "../components/horizontalCardComponent";

function HorizontalListView({ listOfGames, filterMode, onItemClicked }) {
  
  function test() {
   
    if(filterMode === 0){
      return shuffle(listOfGames);
    }else if(filterMode === 1) {
        return listOfGames.filter((game) => {
          return game.latestGame === true;
        }).reverse()
    }else {
      let updatedArray = listOfGames.filter((game) => {
        return game.rating > 3.5;
      }).sort((first, sec) => 
        parseFloat(sec.rating) - parseFloat(first.rating))
      return updatedArray;
    }
  }

  function shuffle(array) {
    var currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  return (
    <div class="flex overflow-x-scroll pb-10 hide-scroll-bar mt-3">
      <div class="flex flex-nowrap ">
        {test().map(function (item, idx) {
          return (
            <HorizontalCardView
              key={idx}
              game={item}
              onItemClick={onItemClicked}
            ></HorizontalCardView>
          );
        })}
      </div>
    </div>
  );
}

export default HorizontalListView;
