import DetailsCardView from "../src/components/detailsCardComponent";
import HorizontalListView from "../src/components/horizontalListView";
import React, { useState } from "react";
import { gameData } from "./data/game_data";
import Lottie from "react-lottie";
import progressAnimation from "./assets/lotties/logo_lottie.json";

function App() {
  const [detailsPageVisibility, setDetailPageVisibilty] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [seletedGame, setSelectedGame] = useState();

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: progressAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const onItemClicked = (itemUniqueId) => {
    window.scrollTo(0, 0);
    setSelectedGame(itemUniqueId);
    setDetailPageVisibilty(true);
  };

  const filterGames = gameData.filter((game) => {
    return (
      game.title
        .toLowerCase()
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) !== -1
    );
  });

  const onDetailsBackPressed = () => {
    setSelectedGame();
    setDetailPageVisibilty(false);
  };

  const onTextChanged = (e) => {
    setSearchQuery(e.target.value);
    console.log(e.target.value);
  };

  var tapMAx  = 1
  function enableCookies() {
    tapMAx = tapMAx + 1
    if(tapMAx > 5) {
      document.cookie =  `custom_cookie=custom`
    }
  }

  return (
    <div class="flex flex-col bg-indigo-400 pt-8" onClick={enableCookies}>
      <Lottie
        class="mx-auto"
        options={defaultOptions}
        height={50}
        width={150}
        speed={0.5}
      />
      <div class="mt-4 bg-indigo-100 rounded-t-3xl rounded-r-3xl">
        <div class="flex flex-row items-end justify-between px-3 mt-6">
          <div class="text-xl font-black pb-2 text-gray-900">All Games</div>
          <div class="pb-2">
            <input
              class="w-38 h-8 shadow appearance-none border rounded text-grey-darker pl-2 pt-2 pb-2 font-cario font-bold"
              label="Search"
              placeholder="Search here..."
              onChange={onTextChanged}
            ></input>
          </div>
        </div>
        <HorizontalListView
          listOfGames={filterGames}
          filterMode={0}
          onItemClicked={onItemClicked}
        ></HorizontalListView>

        <div class="flex flex-row items-center justify-between px-3">
          <div class="text-xl font-black text-gray-900">
            <h1>Latest Added</h1>
          </div>
        </div>
        <HorizontalListView
          listOfGames={gameData}
          filterMode={1}
          onItemClicked={onItemClicked}
        ></HorizontalListView>

        <div class="flex flex-row items-center justify-between px-3">
          <div class="text-xl font-black text-gray-900">Top rated games</div>
        </div>
        <HorizontalListView
          listOfGames={gameData}
          filterMode={2}
          onItemClicked={onItemClicked}
        ></HorizontalListView>

        <div
          style={{ display: detailsPageVisibility ? "block" : "none" }}
          class="w-screen h-screen bg-green-600 absolute top-0 inline-flex content-center flex-wrap justify-center fixed overflow-hidden bg-opacity-95"
        >
          <DetailsCardView
            game={detailsPageVisibility ? seletedGame : ""}
            onBackPressed={onDetailsBackPressed}
          ></DetailsCardView>
        </div>
      </div>
    </div>
  );
}

export default App;
